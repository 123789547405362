import {
    postRequest,
    getRequest,
    upLoadRequest
} from "./request";
// 推荐位
export const positionDetail = data => postRequest("/api/system.position.content/detail", data);
// 站点
export const siteDetail = data => postRequest("/api/site/detail", data);
// 字典数据
export const dictData = data => postRequest("/api/dict.data/all", data);
// 上传图片
export const upLoad = data => upLoadRequest("/api/upload/image", data);
// 字典数据
export const upLoadImage = data => postRequest("/api/upload/image", data);
// 人员注册
export const peopleRegister = data => postRequest("/api/people/register", data);
// 打卡
export const clockinSubmit = data => postRequest("/api/people.clockin/submit", data);
// 登陆
export const login = data => postRequest("/staff/staff/login", data);
// 详情
export const detail = data => postRequest("/staff/staff/detail", data);
// 添加
export const peopleAdd = data => postRequest("/staff/people/add", data);
// 打卡记录
export const clockinLists = data => postRequest("/staff/people.clockin/lists", data);
// 打卡配置
export const getPeopleClockinData = data => postRequest("/api/setting/getPeopleClockinData", data);
// 绑定用户
export const bindUser = data => postRequest("/staff/wechat/bindUser", data);
// 解绑用户
export const unbindUser = data => postRequest("/staff/wechat/unbindUser", data);
// 配置
export const config = data => postRequest("/api/wechat/config", data);
// 微信登陆
export const wxlogin = data => postRequest("/staff/wechat/login", data);
// 打卡
export const clockinCheck = data => postRequest("/api/people.clockin/check", data);
// 微信登陆
export const clockinCommit = data => postRequest("/api/people.clockin/commit", data);

